.componentcss .componentcssimm .table-responsive.dx-g-bs4-table-container
{
    height:430px !important;
}

.componentcss .componentcssimm .popup-content
{width: 50%!important;}

.componentcss .componentcss table th {
    background-color: #f4f4f4;
    font-weight: 600;
    padding: 0px;
    font-size: 12px;
    border-color: #666;
    vertical-align: unset;
    padding: 0px 0px 0px 10px!important;
}

#collapseThreepat .newpostpopup table th { padding: 3px 7px!important; font-size:12px!important; }

#collapseThreepat .newpostpopup input[type=checkbox]{
  margin-top:15px;
  }
  
#collapseThreepat .newpostpopup { max-height: 505px!important;
  overflow: hidden; }

.componentcss .componentcssimm table th input{ font-size: 12px; font-weight:normal; height:22px!important; border:0px; }

.componentcss .componentcss .modal-body {
    padding: 0px 5px!important;
    margin: 0px!important;
  }

  .immun {padding-right: 0px; padding-left: 0px; margin-left: 0px; margin-right: 0px; font-size:12px; margin-top:1px; }

  /*.componentcss .form-control { padding: 0px 12px; }*/

  .immuntext { margin-top: 5px; padding-left: 5px; margin-right: 5px; font-size: 12px; }

  .immuntcomment { width: 100%; height: 50px; border: 1px solid #cccccc; }


  .componentcss .table-responsive.dx-g-bs4-table-container {
    height: 450px !important;
    overflow-y: scroll;
    border: 1px solid #ddd;
}

.newpostpopup2 .react-datepicker__input-container input{
  width: 100%;
}