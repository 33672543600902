
.investigationTable >tbody>tr:nth-of-type(even) {
    background-color:none;
}

.investigationTable .firstColor {
    background-color: #f4f4f4;
}

.investigationTable .secondColor {
    background-color: #ddebf6;
}



.investigationTable th{
    background-color: #f4f4f4;
    font-size: 11px;
    padding: 3px 5px!important;
    border-width: 2px;
    line-height: 1.42857143;
    border-color: #fefefe;
    font-weight: bold;
}

.investigationTable th input[type=checkbox], input[type=radio] {  margin-top:0px; }

.investigationTable .selectedBg{
    background-color: #4680ff;
    color: #fff;
}

.investigationTable .yearSeperator{
    border-top:3px solid #d60d0d;
}


.addinvestigation .react-datepicker__input-container input {
    /* width: 100%; */
    width: 109px;
}
.investigationTable  .firstColor:hover{
    background-color: #337ab7!important;
    color: #fff!important;
}
.investigationTable  .secondColor:hover{
    background-color: #57b846!important;
    color: #fff!important;
}
#notesdiv5 .invesvalue .popup-content {
    width: 70%!important;
}
#notesdiv5 .popup-content {
    width: 70%!important;
}
#notesdiv5 .form-control {
     padding: 0px 0px 0px 12px; 
}

.addinvestigation .form-group .col-sm-6 { margin: 10px 0px 0px;}

