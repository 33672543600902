/* form/login.css */
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

}

.Login {
  -webkit-box-shadow: 4px 6px 21px -10px rgba(0,0,0,0.75);
-moz-box-shadow: 4px 6px 21px -10px rgba(0,0,0,0.75);
box-shadow: 4px 6px 21px -10px rgba(0,0,0,0.75);
}