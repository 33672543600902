.popup-content {
    width: 99%!important
}
ul.nav-menu-list-style .nav-header {
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #a09b9b;
    display: block;
    margin: 0;
    line-height: 34px;
    padding: 0px 8px;
    font-size: 15px;
    font-weight: 300;
}
ul.bullets {
   /* list-style: inside disc;*/
}

.listinbox
{
    margin-right: 0px;
    font-size: 12px;
    padding: 0px;
    clear: both;
    line-height: 4px;
}
.listbox label {
    margin-bottom: 0px;
}

.listbox-title {
    font-size: 14px;
    color: #000000;
    padding: 0px;
}
.popup-content .react-datepicker__input-container input
{
    margin-top: 0px!important;
}