table tbody tr:nth-of-type(even) {
    background-color: #f4f4f4;
}
/*.observation td{
    background-color: #333;
}*/
.observation tbody>tr:nth-of-type(odd) {
  background-color: #ffffff!important;
}
.observation tbody>tr:nth-of-type(even) {
  background-color: #eaf8e6!important;
}
.observation th{
    background-color: #333;
}
.pointer {cursor: pointer;}

.selectedBg {
    /*background-color: #337ab7;*/
    background-color: #57b846;
    color: white;
}

.observation .selectedTdBg{
background-color: #dedede;


}

.boldd{
    font-weight: 600;
}

.cellwidth { padding: 5px; min-width: 90px; font-size: 12px;}

.cellhead {  padding: 2px 5px!important; min-width: 200px; }

.cellheadoth {  padding: 2px 5px!important; }