table tbody tr:nth-of-type(even) {
    background-color: #f4f4f4;
}
/*.observation td{
    background-color: #f4f4f4;
}*/
.pointer {cursor: pointer;}

.selectedBg {
    /*background-color: #337ab7;*/
    background-color: #57b846;
    color: white;
}

.observation .selectedTdBg{
background-color: #dedede !important;;
 

}

.boldd{
    font-weight: 600;
}

#p_wrap, #Investigationdetails {
    white-space: pre-line;
  }