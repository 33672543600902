/******************* BASIC STYLING **********************/
.scroll_tabs_container {
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  text-align: left;
  height: 40px;
  /*margin-bottom: 10px;*/
}

ul.scroll_tabs_container {
  list-style: none;
}

.scroll_tabs_container div.scroll_tab_inner {
  height: 40px;
}

.scroll_tabs_container div.scroll_tab_inner span, .scroll_tabs_container div.scroll_tab_inner li {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 40px;
  font-size: 14px;
  background-color: #ffffff;
  color: #333333;
  cursor: pointer;

  -webkit-transform: perspective(100px) rotateX(30deg);
  -moz-transform: perspective(100px) rotateX(30deg);

}

.scroll_tabs_container div.scroll_tab_inner li {
  display: -moz-inline-stack;
  display: inline-block;
  *display: inline;
  list-style-type: none;
}

.scroll_tabs_container div.scroll_tab_inner span.scroll_tab_left_finisher {
  padding: 0px;
  width: 0px;
}

.scroll_tabs_container div.scroll_tab_inner span.scroll_tab_right_finisher {
  padding: 0px;
  width: 0px;
}

.scroll_tabs_container .scroll_tab_left_button {
  height: 40px;
  /*background-color: #ffffff;*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_container .scroll_tab_left_button::before {
  content: "\25C0";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_container .scroll_tab_left_button_over {
  background-color: #999999;
}

.scroll_tabs_container .scroll_tab_left_button_disabled {
  color: #AAAAAA;
  background-color: #ffffff;
}

.scroll_tabs_container .scroll_tab_right_button {
  height: 40px;
  /*background-color: #ffffff;*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_container .scroll_tab_right_button::before {
  content: "\25B6";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_container .scroll_tab_right_button_over {
  background-color: #999999;
}

.scroll_tabs_container .scroll_tab_right_button_disabled{
  color: #AAAAAA;
  background-color: #ffffff;
}

/****************** LIGHT THEME **************************/
.scroll_tabs_theme_light {
  height: 42px;
}

.scroll_tabs_theme_light div.scroll_tab_inner {
  height: 42px;
}

.scroll_tabs_theme_light div.scroll_tab_inner span, .scroll_tabs_theme_light div.scroll_tab_inner li {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 40px;
  font-size: 14px;
  /*background-color: #ffffff;
  border-left: 1px solid #999999;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #999999;*/
  color: #333333;
  cursor: pointer;
}
.paynowlab  { color: #666666; }

.paynowlab .popup-content label {
  margin-top: 20px;
}

.paynowlab .popup-content .modal-body .form-group label {
  margin: 0px;
}

.popup-content .paynowlab table th { background: #666; }

.creatac { color: #666666; }

.invotext { color: #666666; }

.invotext  .form-group label {
  margin: 0px!important; padding:2px 5px;
}


.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_first, .scroll_tabs_theme_light div.scroll_tab_inner li.scroll_tab_first {
  border-left: 0px;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_left_finisher {
  padding: 0px;
  width: 0px;
  /*background-color: #ffffff;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;*/
  display:none!important;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_right_finisher {
  padding: 0px;
  width: 0px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /*background-color: #ffffff;
  border-left: 0px;
  border-right: 1px solid #999999;*/
  display:none!important;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_over, .scroll_tabs_theme_light div.scroll_tab_inner li.scroll_tab_over {
  background-color: #999999;
}

/*.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_first_over {
  background-color: #999999;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_left_finisher_over {
  background-color: #999999;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_right_finisher_over {
  background-color: #999999;
}*/

/*.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_left_finisher_selected {
  background-color: #AAAAAA;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_right_finisher_selected {
  background-color: #AAAAAA;
}*/


.scroll_tabs_theme_light .scroll_tab_left_button {
  height: 42px;
 /* background-color: #ffffff;*/
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 0px solid #999999;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_light .scroll_tab_left_button::before {
  content: "\25C0";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_theme_light .scroll_tab_left_button_over {
  background-color: #999999;
}

.scroll_tabs_theme_light .scroll_tab_left_button_disabled {
  color: #AAAAAA;
  background-color: #ffffff;
}

.scroll_tabs_theme_light .scroll_tab_right_button {
  height: 42px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /*background-color: #ffffff;
  border: 1px solid #999999;*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_light .scroll_tab_right_button::before {
  content: "\25B6";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_theme_light .scroll_tab_right_button_over {
  background-color: #999999;
}

.scroll_tabs_theme_light .scroll_tab_right_button_disabled{
  color: #AAAAAA;
  background-color: #ffffff;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.tab_selected, .scroll_tabs_theme_light div.scroll_tab_inner li.tab_selected {
  background-color: #AAAAAA;
}

/*.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_first_selected {
  background-color: #AAAAAA;
}*/

/****************** DARK THEME **************************/
.scroll_tabs_theme_dark {
  height: 42px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner {
  height: 42px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span, .scroll_tabs_theme_dark div.scroll_tab_inner li {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 40px;
  font-size: 14px;
  background-color: #333333;
  border-left: 1px solid #222222;
  border-top: 1px solid #222222;
  border-bottom: 1px solid #222222;
  color: #FFFFFF;
  cursor: pointer;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_first, .scroll_tabs_theme_dark div.scroll_tab_inner li.scroll_tab_first {
  border-left: 0px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_left_finisher {
  padding: 0px;
  width: 10px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_right_finisher {
  padding: 0px;
  width: 10px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0px;
  border-right: 1px solid #222222;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_over, .scroll_tabs_theme_dark div.scroll_tab_inner li.scroll_tab_over {
  background-color: #555555;
}

.scroll_tabs_theme_dark .scroll_tab_left_button {
  height: 42px;
  background-color: #333333;
  color: #FFFFFF;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #222222;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_dark .scroll_tab_left_button::before {
  content: "\25C0";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_theme_dark .scroll_tab_left_button_over {
  background-color: #666666;
}

.scroll_tabs_theme_dark .scroll_tab_left_button_disabled {
  color: #444444;
  background-color: #333333;
}

.scroll_tabs_theme_dark .scroll_tab_right_button {
  height: 42px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #333333;
  border: 1px solid #222222;
  color: #FFFFFF;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_dark .scroll_tab_right_button::before {
  content: "\25B6";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_theme_dark .scroll_tab_right_button_over {
  background-color: #666666;
}

.scroll_tabs_theme_dark .scroll_tab_right_button_disabled{
  color: #444444;
  background-color: #333333;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.tab_selected, .scroll_tabs_theme_dark div.scroll_tab_inner li.tab_selected {
  background-color: #666666;
}

 

.scroll_tabs_theme_light div.scroll_tab_inner  .scroll_tab_active_other {
 /*background-color: #e8e8e8;
color: #000000;
-webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);*/

height:50px;
  background: #44514e;
  color:#ffffff;
  border-radius: 4px;
  border:1px solid #ccc;
  margin:0 10px 0;
  box-shadow: 0 0 2px  #fff inset;


 -webkit-transform: perspective(100px) rotateX(30deg);
 -moz-transform: perspective(100px) rotateX(30deg);

}
.scroll_tabs_theme_light div.scroll_tab_inner .scroll_tab_active_male {
  /*background-color: #e8e8e8;
 color: #000000;
 -webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 -moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);*/
 
 height:50px;
   background:deepskyblue;
   color:#ffffff;
   border-radius: 4px;
   border:1px solid #ccc;
   margin:0 10px 0;
   box-shadow: 0 0 2px  #fff inset;
 
 
  -webkit-transform: perspective(100px) rotateX(30deg);
  -moz-transform: perspective(100px) rotateX(30deg);
 
 }
 .scroll_tabs_theme_light div.scroll_tab_inner  .scroll_tab_active_female {
  /*background-color: #e8e8e8;
 color: #000000;
 -webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 -moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);*/
 
 height:50px;
   background: #e756cf;
   color:#ffffff;
   border-radius: 4px;
   border:1px solid #ccc;
   margin:0 10px 0;
   box-shadow: 0 0 2px  #fff inset;
 
 
  -webkit-transform: perspective(100px) rotateX(30deg);
  -moz-transform: perspective(100px) rotateX(30deg);
 
 }
 .scroll_tabs_theme_light div.scroll_tab_inner  .scroll_tab_active_female a {
  color: #fff;
  text-decoration: none;
}

 .scroll_tab_active_female {
  /*background-color: #e8e8e8;
 color: #000000;
 -webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 -moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);*/
 
 height:50px;
   background: #e756cf;
   color:#ffffff;
   border-radius: 4px;
   border:1px solid #ccc;
   margin:0 10px 0;
   box-shadow: 0 0 2px  #fff inset;
 
 
  -webkit-transform: perspective(100px) rotateX(30deg);
  -moz-transform: perspective(100px) rotateX(30deg);
 
 }

 .scroll_tab_active_other {
  /*background-color: #e8e8e8;
 color: #000000;
 -webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 -moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);*/
 
 height:50px;
   background: #93908c;
   color:#ffffff;
   border-radius: 4px;
   border:1px solid #ccc;
   margin:0 10px 0;
   box-shadow: 0 0 2px  #fff inset;
 
 
  -webkit-transform: perspective(100px) rotateX(30deg);
  -moz-transform: perspective(100px) rotateX(30deg);
  
 }
.investigationTable .firstColor:hover {
  background-color: #57b846!important;
  color: #fff!important;
}

.scroll_tab_active a{ color:#ffffff; }

.popup-content .popup-content {
  width: 70%!important;
}