/* visitnotes/visitnotes.css */
.overflow {
    overflow-y: scroll;
    height: 150px;
  }

  .visit-notes-wrapper {
    margin: 10px;
  }
  .rdw-editor-main{
    height: 800px;
    overflow: hidden;
  }
  .visit-notes-wrapper .section-title {
    background-color: #f4f4f4;
    font-size: 16px;
    padding: 5px 15px;
    font-weight: 400;
  }

  /*.visit-notes-wrapper .add-condition-input {
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #cccccc;
    margin-top: 2px;
  }
*/
  .visit-notes-wrapper .add-condition-button {
    display: flex;
    align-items: center;
    justify-content: left;
    height: auto;
    padding: 3px 10px;
  }

  .condition-list {
    margin-top: 0px;
  }

  .condition-list .condition-list-item {
    border: 0px;
    border-radius: 0px;
    border-bottom: 0.5px solid #cccccc;
  }

  .condition-list-item {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 5px;
  }

  .condition-type {
    display: flex;
    margin-left: 0%;
    font-weight: 400;
  }

  .condition-type-item {
    margin-right: 30px;
  }

  .condition-type .condition-type-item label,
  .add-to-my-favourites label,
  .add-to-todays-reason-checkbox label,
  .add-to-diagnosis-checkbox label {
    font-weight: 400;
    margin-left: 17px;
    margin-top: -19px;
  }

  .add-to-my-favourites {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .add-to-todays-reason-checkbox,
  .add-to-diagnosis-checkbox {
    text-align: left;
    margin-top: 10px;
  }

.calendar .firstColor {
    background-color: #f4f4f4 !important;
}

.calendar .secondColor {
    background-color: #ddebf6 !important;
}

.calendar .yearSeperator{
    border-top:3px solid #d60d0d !important;
}
.modal-body{
  padding: 5px 15px;
}
.table-responsive.dx-g-bs4-table-container
{
  height:250px !important;
  overflow-y: scroll;
  border: 1px solid #ddd;
}

.general input {
    border: 1px solid #ced4da;
    border-radius: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 5px;
    font-size: 12px;
}

.cardiovascular input {
  border: 1px solid #ced4da;
  border-radius: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 5px;
  font-size: 12px;
}

.respirat input {
  border: 1px solid #ced4da;
  border-radius: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 5px;
  font-size: 12px;
}
.ganito input {
  border: 1px solid #ced4da;
  border-radius: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 5px;
  font-size: 12px;
}
.entlay input {
  border: 1px solid #ced4da;
  border-radius: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 5px;
  font-size: 12px;
}

.skinlay input {
  border: 1px solid #ced4da;
  border-radius: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 5px;
  font-size: 12px;
}