/* correspondence.css */

.selectedBg {
    /*background-color: #337ab7;*/
    background-color: #57b846!important;
    color: white;
}
.record-note-wrapper .table-responsive.dx-g-bs4-table-container
{
    height:300px !important;
}
.record-note-wrapper .clearfix.card-footer.dx-g-bs4-paging-panel
{
    margin-top:30px !important;
}
.record-note-wrapper{
    /*padding: 20px 15px ;
    padding: 5px;*/
    margin-top: 15px;
    border: 1px solid #dddddd;
}
.nomalcss{
    background-color: red;
}
.no-left-padding{
    padding: 0;
}
