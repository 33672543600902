/* visitnotes/tab/tab.css */
.up {
  /*margin-top: 10px;
  background:#f3f1f1;
  padding: 5px;*/
  background-image: linear-gradient(to bottom,#f5f5f5 0,
#e8e8e8 100%);
background-repeat: repeat-x;


}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #ffffff!important;
    font-size: 16px;
    letter-spacing:1px;
}
.nav-link
{
    margin-left:0px;

        font-size: 17px;
}
.table tbody tr td {
    padding: 10px;
    font-size: 12px;
}


.popup-content .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #ffffff!important;

    font-size: 16px;
    letter-spacing:1px;
	background-color:#1ebea5;
	border: 0px solid #dddddd;
}
.componentcss .popup-content a{
    color: #ffffff!important;
    /*background-color: #44514E;*/
}
.popup-content .modal-body .form-group label {
    margin: 5px;
}
.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 10px;
}
.invotext  .form-group label {
    margin-top: 0px!important;
}
.nav > li > a:hover, .nav > li > a:active, .nav > li > a:focus {
    /* color: #444; */
    background: #1ebea5;
}
.nav-tabs>li>a:hover {
    border-color: #1ebea5 #1ebea5 #1ebea5;
}
.nav-pills > li > a {
    border-radius: 0;
    border-top: 0px solid transparent;
    color: #444;
}
.modal-body {
    padding: 0px 0px!important; 
    margin: 0px!important;
}