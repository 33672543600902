/* familysocialhostory/style.css */
.col-padding{
    margin-bottom:  5px;
}
.div-margin{
    margin: 20px 0 0 0;
}
.answer-table tr{
    padding: 5px;
}
.answer-table td{
    padding: 5px;
}
.occtext { color:#666666; }