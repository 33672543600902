/* visitnotes/combindpatienthistory.css */
.patient-detail-nav-item {
    cursor: pointer;
  }
  
  .combined_highlight{
    background-color: #1ebea5!important;
    color:#fff;

    /* -webkit-box-shadow: 0px -2px 11px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -2px 11px -3px rgba(0,0,0,0.75);
    box-shadow: 0px -2px 11px -3px rgba(0,0,0,0.75); */
  }

  .tree-toggle{
    cursor: pointer;
  }

  .red_highlight{
    background-color:#ff0000 !important; 
    color:#ffffff !important;
  }
  .white_highlight{
    background-color:#ffffff; 
    color:#000000;
  }