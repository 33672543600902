#opencurrentrx .popup-content {
    box-shadow: none!important;
    background: transparent!important;
}

#collapseThreepat .px-3 .popup-content {
    box-shadow: none!important;
    background: transparent!important;
}

.react-datepicker__input-container input { }

.obspopup {
    margin: 0px 10%;
    background: #ffffff;
    float: left;
    border-radius: 30px;
}
.obsoutcome {
    margin: 0px 14%;
    background: #ffffff;
    float: left;
    border-radius: 30px;
}
.obsoutcome2 {
    margin: 0px 14%;
    background: #ffffff;
    float: left;
    border-radius: 30px;
}
.obsoutcome3 {
    margin: 0px 20%;
    background: #ffffff;
    float: left;
    border-radius: 30px;
}